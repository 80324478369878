import { BaseElement } from '../../modules/BaseElement';

import * as Optimize from '../../../../../../../plugin_dis_patagonia/cartridge/scripts/helpers/Optimize.js';

class DISImage extends BaseElement {
    constructor() {
        super();
        this.imageOptimizationHelpers = Optimize;
        this.config = {};

        this.paramMap = { scaleWidth: 'sw', scaleHeight: 'sh', quality: 'q' };
    }

    connectedCallback() {
        if (super.connectedCallback) {
            super.connectedCallback();
        }

        let lastSizeParsed = 0;

        this.imageSizes = Object.keys(this.config)
            .filter((key) => key !== 'missingImages' && key !== 'viewTypeMapping')
            .filter((size) => {
                try {
                    const num = Number(size);
                    if (Number.isNaN(num)) {
                        return true;
                    }
                    if (num > lastSizeParsed + 250) {
                        lastSizeParsed = num;
                        return true;
                    }
                } catch (e) {
                    console.warn(e);
                    return false;
                }
                return false;
            });
    }

    buildURL(params = {}) {
        const builtURL = new URL(this.baseURL);
        Object.keys(params).forEach((key) => {
            builtURL.searchParams.set(this.paramMap[key] || key, params[key]);
        });
        return builtURL.toString();
    }

    getSrcSet(sizes) {
        const parts = [];
        const widths = [];
        this.imageSizes.forEach((size) => {
            if (this.config[size] && (!sizes || sizes.includes(size))) {
                const width =
                    this.config[size].scaleWidth % 4 === 0
                        ? this.config[size].scaleWidth
                        : Math.floor(this.config[size].scaleWidth / 4.0) * 4;

                if (!widths.includes(width)) {
                    parts.push(`${this.buildURL(this.config[size])} ${width}w`);
                    widths.push(width);
                }
            }
        });

        return parts.join(', ');
    }

    getPlaceholder() {
        return this.basePlaceholderURL.toString();
    }

    getSrc() {
        return this.baseURL.toString();
    }

    get basePlaceholderURL() {
        return new URL(this.placeholder);
    }

    get baseURL() {
        return new URL(this.base);
    }

    getLQIP() {
        return this.buildURL(this.config.lqip);
    }
}

export default DISImage;

import { html } from 'lit';
import DISImage from './dis-image';
import { toBoolean } from '../../modules/BaseElement';

import { collection as imageSettings } from '../../../../../../../plugin_dis_patagonia/cartridge/preferences/library_config_DIS';

const lqip = (scope) =>
    `src="${scope.getLQIP()}"
    data-lowsrc="${scope.getLQIP()}"
    `;

const template = (scope) => html`
    <img
        class="${scope.classes?.includes('lazyload') ? '' : 'lazyload'} ${scope.classes}"
        style=${scope.styles}
        alt=${scope.alt}
        data-src=${scope.getSrc()}
        data-srcset="${scope.getSrcSet()}"
        ${scope.showLQIP ? lqip(scope) : ''}
        decoding="async"
        data-sizes="auto"
    />
`;

class LibraryDISImage extends DISImage {
    static get properties() {
        return {
            alt: {
                type: String,
            },
            base: {
                type: String,
            },
            classes: {
                type: String,
            },
            styles: {
                type: String,
            },
            showLQIP: {
                attribute: 'show-lqip',
                type: Boolean,
                converter: {
                    fromAttribute: (val) => toBoolean(val),
                },
                state: false,
            },
        };
    }

    constructor() {
        super();
        this.config = {};

        imageSettings.forEach((item) => {
            Object.keys(item).forEach((size) => {
                this.config[size] = item[size];
            });
        });
    }

    connectedCallback() {
        if (super.connectedCallback) {
            super.connectedCallback();
        }

        this.imageSizes = Object.keys(this.config).filter(
            (key) => key !== 'missingImages' && key !== 'viewTypeMapping'
        );
    }

    render() {
        return template(this);
    }
}

export default LibraryDISImage;

import { addGlobalProductTileEvents, initProductTiles } from '../components/productTile';
import { init as autokanaInit } from './autokana';
import * as doubleByte from './double-byte';
import ControllerBlob from '../assets/controllers/controller-blob/script';
import { parseResults } from '../search/search-helpers';

const checkAllAjaxModules = () => {
    $('.lazyload[data-ajax]')
        .not('.lazyloaded-ajax, .lazyloading-ajax')
        .each(function (elem, index) {
            // if no skeleton exists, force load it to avoid layout shift
            if (!this.querySelector('[class*="skeleton"]')) {
                setTimeout(() => {
                    window.lazySizes.loader.unveil(this);
                }, index * 10);
            }
        });
};

const util = {
    /**
     * @name rootDomain
     * @description filters subdomains from strings, returning the root domain and tld
     * - ex: www.patagonia.com -> patagonia.com
     * @param {string} domain domain to process as a string
     */
    rootDomain(domain) {
        const r = /.*\.([^.]*[^0-9][^.]*\.[^.]*[^.0-9][^.]*$)/;
        return domain.replace(r, '$1');
    },

    /**
     * @function
     * @description Remove line breaks from an element
     * @param {string} ele The element
     * @param {boolean} replace Should the line breaks be replaced with spaces?
     */
    removeLineBreaks(ele, replace = false) {
        const theElement = $(ele);
        if (theElement.length > 0) {
            let theText = $(ele).val();
            let replaceString = '';
            if (replace === true) {
                replaceString = ' ';
                theText = theText.replace(/\r?\n|\r/g, replaceString);
            }
            $(ele).val(theText);
        }
    },

    /**
     * Open external URLs in new window
     */
    handleExternalLinks() {
        const windowHost = util.rootDomain(window.location.hostname);
        $('main a[href^="http"]')
            .filter(function () {
                const hrefHost = util.rootDomain(this.hostname);
                return hrefHost && hrefHost !== windowHost;
            })
            .attr('target', '_blank')
            .attr('rel', 'noopener');
    },

    /**
     * appends params to a url
     * @param {string} url - Original url
     * @param {Object} params - Parameters to append
     * @returns {string} result url with appended parameters
     */
    appendToUrl(url, params) {
        let newUrl = url;
        newUrl +=
            (newUrl.indexOf('?') !== -1 ? '&' : '?') +
            Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');

        return newUrl;
    },

    /**
     * @function
     * @description Initialize global events
     */
    globalEvents() {
        const controlKeys = ['8', '46', '45', '36', '35', '38', '37', '40', '39'];

        /**
         * @desc Limit chars per textarea
         * @todo refactor and move to app-specific JS
         */
        $('body')
            .on('keydown', 'textarea[data-character-limit]', function (e) {
                const text = $(this).val();
                const charsLimit = $(this).data('character-limit');
                let charsUsed = 0;

                const rgx = /[\uD83C-\uDBFF\uDC00-\uDFFF]+/gu;
                if (
                    this.id === 'dwfrm_singleshipping_shippingAddress_giftMessage' &&
                    $('.main').data('country') === 'JP' &&
                    !rgx.test(text)
                ) {
                    for (let i = 0; i < text.length; i += 1) {
                        const textChar = text[i];
                        const bytesInChar = encodeURI(textChar).split(/%..|./).length - 1;
                        if (bytesInChar > 1) {
                            charsUsed += 2;
                        } else {
                            charsUsed += 1;
                        }
                    }
                } else {
                    charsUsed = text.length;
                }

                if (
                    charsUsed >= charsLimit &&
                    !(e.ctrlKey || e.metaKey) &&
                    controlKeys.indexOf(e.which.toString()) < 0
                ) {
                    e.preventDefault();
                } else if (e.which === 13 && this.id !== 'description') {
                    e.preventDefault();
                }
            })
            .on('change keyup mouseup input paste', 'textarea[data-character-limit]', function () {
                const text = $(this).val();
                const charsLimit = $(this).data('character-limit');
                let charsUsed = 0;
                const rgx = /[\uD83C-\uDBFF\uDC00-\uDFFF]+/gu;

                if (
                    this.id === 'dwfrm_singleshipping_shippingAddress_giftMessage' &&
                    $('.main').data('country') === 'JP' &&
                    !rgx.test(text)
                ) {
                    for (let i = 0; i < text.length; i += 1) {
                        const textChar = text[i];
                        const bytesInChar = encodeURI(textChar).split(/%..|./).length - 1;
                        if (bytesInChar > 1) {
                            charsUsed += 2;
                        } else {
                            charsUsed += 1;
                        }
                    }
                } else {
                    charsUsed = text.length;
                }

                let charsRemain = charsLimit - charsUsed;
                const currentVal = $(this).val();

                if (charsRemain < 0 && $('.main').data('country') === 'JP' && !rgx.test(text)) {
                    $(this).val(currentVal.slice(0, charsLimit / 2));
                    charsRemain = 0;
                } else if (charsRemain < 0) {
                    $(this).val(currentVal.slice(0, charsLimit));
                    charsRemain = 0;
                }

                $(this).siblings('div.char-count').find('.char-remain-count').html(charsRemain);
            });

        this.limitCharacters();

        // update new window links
        this.targetBlankUpdate();

        util.handleExternalLinks();

        // handle magic play buttons on images that link/modal to youtube
        const magicPlayBtnSelectors = [
            '.card__link-full[href*="youtube.com"]',
            '.card .stretched-link[href*="youtube.com"]',
            '.card__link-full[data-youtube-url*="youtube.com"]',
            '.stretched-link[data-youtube-url*="youtube.com"]',
            '.link-add-play-icon',
        ];

        // render magic video play buttons
        $(magicPlayBtnSelectors.join(', ')).each(function () {
            const parent = $(this).parent();
            if (parent.find('.btn').length) {
                return;
            }
            parent
                .find('img, div.optim-img')
                .first()
                .wrap('<div class="card__magic-play-btn-img-wrap"></div>').after(`
                    <span class="card__magic-play-btn cta-circle cta-circle--svg-right cta-circle-lg cta-circle-light">
                        <figure>
                            <svg class="icon--play"><use href="#icon--play"></use></svg>
                        </figure>
                    </span>
                `);
        });

        // add global events for product tiles
        addGlobalProductTileEvents();

        // global accordion toggle
        document.querySelectorAll('.accordion-group').forEach((group, i) => {
            const accordionToggle = group.querySelector('.accordion-toggle');
            const accordionContent = group.querySelector('.accordion-content');
            const accordionIcon = group.querySelector('.icon--chevron-down');

            // adding Bootstrap attributes
            accordionToggle?.setAttribute('data-bs-toggle', 'collapse');
            accordionToggle?.setAttribute('data-bs-target', `collapsible-${i}`);

            accordionContent?.classList.add('collapse');

            accordionToggle?.addEventListener('click', (e) => {
                e.preventDefault();

                // initiate Bootstrap collapse functionality
                new window.bootstrap.Collapse(accordionContent, {
                    parent: accordionToggle,
                    toggle: true,
                });

                // toggle chevron icon when opened and closed
                accordionIcon.classList.toggle('is-expanded');
            });
        });

        // film FAQ collapse
        $('#collapseFilmFAQMore').on('show.bs.collapse', () => {
            $('.page-film-faq__load-more').addClass('page-film-faq__load-more--hide');
        });

        // extend lazysizes dynamically loaded modules
        document.addEventListener('lazybeforeunveil', (e) => {
            const $target = $(e.target);
            const ajax = $target.data('ajax');
            if (ajax) {
                const targetParent = $target.parent();
                $target.addClass('lazyloading-ajax');
                $target.load(ajax, () => {
                    e.target.classList.remove('lazyloading-ajax');
                    e.target.classList.add('lazyloaded-ajax');

                    if (e.target.classList.contains('ll-search-results')) {
                        parseResults($target.html());
                    } else if (targetParent.find('.product-tile').length) {
                        initProductTiles(targetParent[0]);
                    }

                    const event = document.createEvent('Event');
                    event.initEvent('LazyAjaxLoaded', true, true);
                    document.dispatchEvent(event);

                    // update subhead HTML from response
                    if ($target.data('subhead') && $target.find('.slider-cards__header-subhead')) {
                        $target.find('.slider-cards__header-subhead').html($target.data('subhead'));
                    }

                    // catch any LL in LL, need to re jQuery for new elements
                    $(e.target)
                        .find('[data-ajax], .lazyload-ajax, .lazyload-jq')
                        .not('.lazyload, .lazyloaded-ajax, .lazyloading-ajax')
                        .removeClass('lazyloading', 'lazyloaded')
                        .addClass('lazyload');

                    // reload modules via ControllerModule scan and init product tiles
                    const reload = document.createEvent('Event');
                    reload.initEvent('ReloadModules', true, true);
                    document.dispatchEvent(reload);
                    ControllerBlob.scanAdd(e.target);

                    const ajaxEvent = document.createEvent('Event');
                    ajaxEvent.initEvent('AjaxContentLoaded', true, true);
                    e.target.dispatchEvent(ajaxEvent);

                    if (e.target.dataset?.ajaxReplaceParent === 'true') {
                        const hasMarketingTiles = e.target.querySelector(
                            '.product-tile__wrapper-marketing'
                        );

                        setTimeout(() => {
                            requestAnimationFrame(() => {
                                e.target.after(...e.target.childNodes);
                                e.target.remove();
                                if (hasMarketingTiles) {
                                    const reCalc = document.createEvent('Event');
                                    reCalc.initEvent('ProductList-MarketingAdded', true, true);
                                    document.dispatchEvent(reCalc);
                                }
                            });
                        }, 100);
                    }
                });
            }
        });

        // product tile init if not ajax / lazyload
        if (
            document.querySelector('.product-grid') &&
            !document.querySelector('.product-grid').dataset.ajax
        ) {
            const nonAjaxProductTiles = document.querySelector('.product-grid');
            initProductTiles(nonAjaxProductTiles);
        }

        // force reveal of high-priority items
        $('.lazyload-priority')
            .filter('[data-ajax], .lazyload-ajax, .lazyload-jq')
            .not('.lazyloaded-ajax, .lazyloading-ajax')
            .addClass('lazyload')
            .each(function () {
                window.lazySizes.loader.unveil(this);
            });

        // start lazy-loading jQuery-dependant items now that we're ready
        $('[data-ajax], .lazyload-ajax, .lazyload-jq')
            .not('.lazyloaded-ajax, .lazyloading-ajax')
            .removeClass('lazyloading', 'lazyloaded')
            .addClass('lazyload');

        // Force ajaxed content to exist after Load event
        window.addEventListener('load', () => {
            if (window.lazySizes) {
                if (window.scrollY > 0) {
                    checkAllAjaxModules();
                } else {
                    window.addEventListener('scrollUpdate', checkAllAjaxModules, { once: true });
                }
            }

            if (window.snap && window.snap.creativekit) {
                window.snap.creativekit.initalizeShareButtons(
                    document.querySelectorAll('.snapchat-share-button')
                );
            }
        });

        window.addEventListener('LazyAjaxLoaded', () => {
            util.handleExternalLinks();
        });

        document.addEventListener('triggerFieldValidationEvent', (e) => {
            if (e?.detail?.field) {
                util.triggerFieldValidation(e.detail.field);
            }
        });

        this.fixModalScroll();

        // JP-specific validation
        this.initJPValidation();

        // Share Module

        /**
         * @description - copies the current url to the clipboard, if copying is supported in the navigator object. If this is not supported, any link with that selector will be hidden
         */

        document.querySelectorAll('.social-share-list').forEach((elem) => {
            const COPY_URL_CTA = elem.querySelector('.js-copy-current-url');
            const COPY_BASE_STATE = elem.querySelector('.js-copy-base-state');
            const COPY_SUCCESS_STATE = elem.querySelector('.js-copy-success-state');

            const copyUrlToClipboard = () => {
                const baseStyles = [
                    'color: lime',
                    'background-color: green',
                    'padding: 2px 4px',
                    'border-radius: 2px',
                ].join(';');
                const errorStyles = [
                    'color: red',
                    'background-color: pink',
                    'padding: 2px 4px',
                    'border-radius: 2px',
                ].join(';');
                const params = COPY_URL_CTA.dataset.params
                    ? `?${COPY_URL_CTA.dataset.params}`.replace(/\?\?/, '?')
                    : '';
                const url = new URL(window.location.origin + window.location.pathname + params);

                if (!navigator.clipboard) return;

                navigator.clipboard.writeText(url).then(
                    () => {
                        console.log('%cLink copied!', baseStyles);
                        COPY_SUCCESS_STATE.classList.add('active');
                        COPY_BASE_STATE.classList.add('d-none');
                        COPY_SUCCESS_STATE.classList.remove('d-none');
                        COPY_URL_CTA.setAttribute('title', 'Link copied!');
                        COPY_URL_CTA.removeEventListener('click', copyUrlToClipboard);
                    },
                    (err) => {
                        console.error('%cCould not copy link: ', err, errorStyles);
                    }
                );
            };

            if (COPY_URL_CTA) {
                COPY_URL_CTA.addEventListener('click', copyUrlToClipboard);
            }
        });

        // handle social links
        $('.post__social-icon').on('click', function (e) {
            e.preventDefault();
            const $this = $(this);
            window.open(
                $this.attr('href'),
                'popUpShareWindow',
                `height=450, width=700, top=${window.innerHeight / 2 - 225}, left=${
                    window.innerWidth / 2 - 350
                }, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`
            );
            return false;
        });

        // set first hero-main data-banner-type to "hero", other on page will remain as generic set at template level
        // done this way as a work around for lazyloaded banners
        if (document.querySelector('.hero-main')) {
            const bannerBtns = document.querySelector('.hero-main').querySelectorAll('.btn');
            for (let i = 0; i < bannerBtns.length; i += 1) {
                bannerBtns[i].setAttribute('data-banner-type', 'hero');
            }
        }
    },
    /**
     * @function
     * @description Initialize the JP form validation
     */
    initJPValidation() {
        let currentJPPostalCode = '';
        if ($('main').data('country') === 'JP') {
            // convert double byte fields to single byte
            $(document).on('blur', '[data-pristine-double-to-single]', function () {
                doubleByte.doubleToSingle($(this), null);
            });

            if (
                $('input[name$="_firstName"]').length > 0 &&
                $('input[name$="_firstNameKana"]').length > 0
            ) {
                const firstNameInputs = [];
                const firstNameKanaInputs = [];

                $('input[name$="_firstName"]').each((index, el) => {
                    firstNameInputs.push($(el).attr('name'));
                });

                $('input[name$="_firstNameKana"]').each((index, el) => {
                    firstNameKanaInputs.push($(el).attr('name'));
                });

                for (let i = 0; i < firstNameInputs.length; i += 1) {
                    if (i < firstNameKanaInputs.length) {
                        autokanaInit(
                            `input[name$="${firstNameInputs[i]}"]`,
                            `input[name$="${firstNameKanaInputs[i]}"]`
                        );
                    }
                }
            }
            if (
                $('input[name$="_lastName"]').length > 0 &&
                $('input[name$="_lastNameKana"]').length > 0
            ) {
                const lastNameInputs = [];
                const lastNameKanaInputs = [];

                $('input[name$="_lastName"]').each((index, el) => {
                    lastNameInputs.push($(el).attr('name'));
                });

                $('input[name$="_lastNameKana"]').each((index, el) => {
                    lastNameKanaInputs.push($(el).attr('name'));
                });

                for (let i = 0; i < lastNameInputs.length; i += 1) {
                    if (i < lastNameKanaInputs.length) {
                        autokanaInit(
                            `input[name$="${lastNameInputs[i]}"]`,
                            `input[name$="${lastNameKanaInputs[i]}"]`
                        );
                    }
                }
            }

            // Store postal code so we don't rerun city/state autopopulation if the postal code didn't change
            $(document).on('focus', 'input[name$="_postal"]', function () {
                currentJPPostalCode = $(this).val();
            });

            $(document).on('blur', 'input[name$="_postal"]', function () {
                const postalCodeField = $(this);

                // Add dash to postal code if it is missing
                if (
                    postalCodeField.val().indexOf('-') === -1 &&
                    postalCodeField.val().length === 7
                ) {
                    const postalCodeVal = postalCodeField.val();
                    postalCodeField.val(
                        [postalCodeVal.slice(0, 3), '-', postalCodeVal.slice(3)].join('')
                    );
                }

                // Convert double byte to single byte characters, and trigger postal code lookup
                if (postalCodeField.val() !== currentJPPostalCode) {
                    doubleByte.doubleToSingle($(this), (convertedField) => {
                        // Need to manually validate field with Pristine; relying on pristine-is-valid class to be updated in time for this if statement doesn't work here
                        // eslint-disable-next-line no-constant-condition
                        if (new Pristine(convertedField[0])) {
                            const jpPostalCodeLookup = new CustomEvent('jpPostalCodeLookup', {
                                detail: {
                                    input: postalCodeField[0],
                                    postalCode: convertedField.val().trim(),
                                },
                            });
                            document.dispatchEvent(jpPostalCodeLookup);
                        }
                    });
                }
            });

            // Add dash to postal code if it is missing
            if ($('input[name$="postal"]').length > 0) {
                $('form.shipping-address-form, form.billing-payment-address-form').on(
                    'submit',
                    () => {
                        $('input[name$="postal"]').each(function () {
                            const postal = $(this).val();
                            if (postal.indexOf('-') === -1 && postal.length === 7) {
                                $(this).val([postal.slice(0, 3), '-', postal.slice(3)].join(''));
                            }
                        });
                    }
                );
            }

            // remove hyphens from phone on form submit (to account for previously saved numbers with hyphens) or on blur
            if ($('input[name$="phone"]').length > 0) {
                $(document).on('submit', 'form', () => {
                    $('input[name$="phone"]').each(function () {
                        $(this).val($(this).val().replace(/-/g, ''));
                    });
                });
                $(document).on('blur', 'input[name$="phone"]', function () {
                    const phoneField = $(this);

                    doubleByte.doubleToSingle($(this), () => {
                        phoneField.val(phoneField.val().replace(/-/g, ''));
                    });
                });
            }

            // Listen to birthday selector changes, and if all are unselected, remove required attribute + revalidate with pristine
            $('.jp-birthday').on('change', function () {
                const birthdaySelects = $(this).closest('.jp-birthday-wrapper').find('select');
                let allEmpty = true;

                birthdaySelects.each(function () {
                    if ($(this).val() !== '') {
                        allEmpty = false;
                    }
                });

                if (allEmpty) {
                    birthdaySelects.each(function () {
                        $(this).removeAttr('required');
                    });

                    const form = $(this).closest('form')[0];
                    if (form && (form.classList ? !form.classList.contains('suppress') : true)) {
                        const pristine = new Pristine(form);
                        pristine.validate();
                    }
                }
            });
        }
    },
    /**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     */
    limitCharacters() {
        $('form')
            .find('textarea[data-character-limit]')
            .each(function () {
                const characterLimit = $(this).data('character-limit');
                const charCountHtml = String.format(
                    $(this).data('message'),
                    `<span class="char-remain-count">${characterLimit}</span>`,
                    `<span class="char-allowed-count">${characterLimit}</span>`
                );
                let charCountContainer = $(this).next('div.char-count');
                if (charCountContainer.length === 0) {
                    charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
                }
                charCountContainer.html(charCountHtml);

                // trigger the keydown event so that any existing character data is calculated
                $(this).trigger('change');
            });
    },
    /**
     * @function
     * @description Adds rel="noopener" to target="_blank" links
     */
    targetBlankUpdate() {
        $('a[target="_blank"]:not([rel="noopener"])').each(function () {
            $(this).attr('rel', 'noopener');
        });
    },
    /**
     * @function
     * @description Works like native `ScrollIntoView` but for within scrollable elements
     * @param {object} parent Parent Container
     * @param {object} child Child within parent to scroll into view
     */
    scrollIntoView(parent, child) {
        if (!parent || !child) {
            return;
        }

        // Where is the parent on page
        const parentRect = parent.getBoundingClientRect();

        // What can you see?
        const parentViewableArea = {
            height: parent.clientHeight,
            width: parent.clientWidth,
        };

        // Where is the child
        const childRect = child.getBoundingClientRect();
        const isViewable =
            childRect.top >= parentRect.top &&
            childRect.top <= parentRect.top + (parentViewableArea.height - 50);

        // if you can't see the child try to scroll parent showing there is at least one item avoe
        if (!isViewable) {
            /* eslint-disable no-param-reassign */
            parent.scrollTop = childRect.top + parent.scrollTop - parentRect.top;
        }
    },

    /**
     * @function
     * @description remove special chars on form submit event from all text fields
     * @param {Object} e event
     */
    removeInvalidCharsForm(form) {
        // eslint-disable-next-line no-useless-escape
        const pattern = /['"=&<>%\/éÉ^\[\]]/g;
        $(form)
            .find('textarea:not([name="g-recaptcha-response"])')
            .each(function () {
                const el = $(this);
                el.val(el.val().replace(pattern, ' '));
            });

        $(form)
            .find('input[type="text"]:not([name="g-recaptcha-response"])')
            .each(function () {
                const el = $(this);
                el.val(el.val().replace(pattern, ' '));
            });
    },
    /**
     * @function
     * @description Update url paramater
     * @param {String} url Url to update
     * @param {String} param Key to the paramater you want to change
     * @param {String} paramVal New value you want to update with
     */
    updateURLParameter(url, param, paramVal) {
        let newAdditionalURL = '';
        let tempArray = url.split('?');
        const baseURL = tempArray[0];
        const additionalURL = tempArray[1];
        let temp = '';
        if (additionalURL) {
            tempArray = additionalURL.split('&');
            for (let i = 0; i < tempArray.length; i += 1) {
                if (tempArray[i].split('=')[0] !== param) {
                    newAdditionalURL += temp + tempArray[i];
                    temp = '&';
                }
            }
        }

        const rowsTxt = `${temp}${param}=${paramVal}`;
        return `${baseURL}?${newAdditionalURL}${rowsTxt}`;
    },

    /**
     * @function
     * @description get url paramater value by key
     * @param {String} param Key to the paramater you want to get
     * @param {String} url Optional: URL to parse will fallback to current page url if nothing is passed
     */
    getURLParameter(param, url) {
        // TODO: switch to https://developer.mozilla.org/en-US/docs/Web/API/URL/searchParams when IE is unsupported
        if (!url) url = window.location.href;
        // eslint-disable-next-line no-useless-escape
        param = param.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp(`[?&]${param}(=([^&#]*)|&|#|$)`);
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },

    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL(url, name, value) {
        // quit if the param already exists
        if (url.indexOf(`${name}=`) !== -1) {
            return url;
        }
        const separator = url.indexOf('?') !== -1 ? '&' : '?';
        return `${url + separator + name}=${encodeURIComponent(value)}`;
    },

    /**
     * @function
     * @description appends parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {Object} params key : value object with the params to be added
     */
    appendParamsToUrl(url, params) {
        const keys = Object.keys(params);
        let output = url;
        keys.forEach((key) => {
            output = util.appendParamToURL(output, key, params[key]);
        });
        return output;
    },

    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
    getQueryStringParams(qs) {
        if (!qs || qs.length === 0) {
            return {};
        }
        const params = {};
        const unescapedQS = decodeURIComponent(qs);
        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        unescapedQS.replace(/([^?=&]+)(=([^&]*))?/g, ($0, $1, $2, $3) => {
            params[$1] = $3;
        });
        return params;
    },

    /**
     * @function * @description get the next pref num value for the url
     */
    getNextPrefNum(url) {
        if (!url) {
            url = window.location.href;
        }
        const params = url.slice(url.indexOf('?') + 1).split('&');

        let finalNum = 0;
        for (let i = 0; i < params.length; i += 1) {
            const param = params[i].split('=');
            if (param[0].indexOf('prefn') > -1) {
                const num = param[0].match(/prefn(\d+)/);
                if (num && Number(num[1]) > finalNum) {
                    finalNum = Number(num[1]);
                }
            }
        }
        return finalNum + 1;
    },

    /**
     * Send events to the field to trigger validation
     * @param {HTMLElement} field - the field
     */
    triggerFieldValidation(field) {
        if (!field) {
            return;
        }

        requestAnimationFrame(() => {
            // Validate input
            field.dispatchEvent(new Event('validateInput'));

            // Make sure we have a label before we use it
            const label = field.closest('label');
            if (label && label.classList) {
                // Toggle has-value class on label if value exists
                label.classList.toggle('has-value', field.value);

                // Add had-focus class to label if value exists
                if (field.value) {
                    label.classList.add('had-focus');
                }
            }

            // Validate form
            const form = field.closest('form');
            if (form && (form.classList ? !form.classList.contains('suppress') : true)) {
                const pristine = new Pristine(form);
                pristine.validate();
            }
        });
    },

    /**
     * @function
     * @description Sets custom select to new value
     * @param {string} fieldName - last part of a field name
     * @param {string} newValue - updated value ID
     * @param {string} formClassName - parent form class
     * @param {boolean} triggerChange - if a change should be triggered for non .custom-select selects
     */
    setCustomSelectValue(fieldName, newValue, formClassName, triggerChange) {
        const $select = $(`.${formClassName} select[name$=${fieldName}]`);

        if (newValue === undefined || newValue === null || $select.length === 0) {
            return;
        }

        if ($select.hasClass('custom-select')) {
            $select.find('option').each((index, el) => {
                const $option = $(el);

                $option.removeAttr('selected');

                if (newValue === '' && index === 0) {
                    $option.prop('selected', true);
                } else if (
                    $option.attr('id') &&
                    typeof $option.attr('id') === 'string' &&
                    newValue &&
                    typeof newValue === 'string' &&
                    $option.attr('id').toLowerCase() === newValue.toLowerCase()
                ) {
                    $option.prop('selected', true);
                }
            });

            $select.trigger('change');
        } else {
            $select.val(newValue);
        }

        if (fieldName === 'country') {
            const phoneInput = $(`.${formClassName} input[name$=_phone]`);

            if (phoneInput.length > 0 && newValue) {
                const maskPhone = new CustomEvent('maskPhone', {
                    detail: {
                        input: phoneInput[0],
                        country: newValue.toUpperCase(),
                    },
                });
                document.dispatchEvent(maskPhone);
            }
        }

        if ($select.length > 0 && triggerChange) {
            // NOTE: Must be a native non-jquery change event to work with loqate when changing country selects
            $select[0].dispatchEvent(new Event('change'));
        }

        util.triggerFieldValidation($select[0]);
    },

    fixModalScroll() {
        if (!('MutationObserver' in window)) {
            return;
        }
        const { body } = document;

        // Options for the observer (which mutations to observe)
        const config = {
            attributes: true,
        };
        let modal = null;
        let modalSearch = null;
        let modalSearchTimes = 0;

        // Callback function to execute when mutations are observed
        const callback = function (mutationsList) {
            mutationsList.forEach((mutation) => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    if (document.body.classList.contains('modal-open')) {
                        if (
                            !document.body.classList.contains('modal-open--small-modal') &&
                            !modal
                        ) {
                            // we need to search for a modal that opened
                            // due to the transition, this is not immediately available
                            modalSearch = setInterval(() => {
                                modal = document.querySelector('.modal.show');
                                modalSearchTimes += 1;

                                if (modalSearchTimes > 20) {
                                    clearInterval(modalSearch);
                                    modalSearch = null;
                                    modalSearchTimes = 0;
                                    return;
                                }

                                if (modal) {
                                    if (
                                        !modal.classList.contains('modal__full') &&
                                        !document.querySelector('.modal-backdrop.fade.show')
                                    ) {
                                        document.body.classList.add('modal-open--small-modal');
                                    }
                                    clearInterval(modalSearch);
                                    modalSearch = null;
                                    modalSearchTimes = 0;
                                }
                            }, 100);
                        }
                    } else {
                        // prevent a loop
                        if (document.body.classList.contains('modal-open--small-modal')) {
                            document.body.classList.remove('modal-open--small-modal');
                        }
                        if (modalSearch) {
                            clearInterval(modalSearch);
                            modalSearch = null;
                        }
                        modal = null;
                    }
                }
            });
        };

        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        observer.observe(body, config);
    },

    passiveSupport() {
        let passive = false;
        const debug = false;

        try {
            window.addEventListener(
                'test',
                null,
                Object.defineProperty({}, 'passive', {
                    /* eslint-disable-next-line */
                    get() {
                        passive = {
                            passive: true,
                        };
                    },
                })
            );
        } catch (err) {
            if (debug) {
                console.error(err);
            }
        }

        return passive;
    },
};

export const { passiveSupport } = util;
export const { appendToUrl } = util;
export const { setCustomSelectValue } = util;
export const { scrollIntoView } = util;
export const { initJPValidation } = util;
export const { triggerFieldValidation } = util;
export default util;
